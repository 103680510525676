
import React, { useState, useEffect, useCallback } from 'react';
import Paper from '@mui/material/Paper';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import MenuIcon from '@mui/icons-material/Menu';

import { queryAllByAltText } from '@testing-library/react';
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux';
import { selectDiscourse, selectSearching, selectProducts, addToDiscourse, setSearching, setProducts, clearData } from '../redux/dataSlice';
import { selectUser, setUser, logout} from '../redux/identitySlice';
import { auth } from "../firebase";
import { ProductCard } from '../components/ProductCard'
import { ChatDialog } from '../components/ChatDialog'
import { TextEntry } from '../components/TextEntry'




const {RestFunctions} = require('../repositories/Network')
const {getProductData} = require('../samples/GetProducts')



function QueryPage(props) {

    const dispatch = useDispatch()
    const discourse = useSelector(selectDiscourse)
    const searching = useSelector(selectSearching)
    const products = useSelector(selectProducts)

    const user = useSelector(selectUser)

    const [query, setQuery] = useState('');
    const [chatEntry, setChatEntry] = useState('');
    const [errorMessage, setErrorMessage] = useState();
    const [searchStatus, setSearchStatus] = useState(null);

    console.log('Connecting to server:', process.env.REACT_APP_LOCAL_SERVER, 'Version:', process.env.REACT_APP_VERSION)

    const SERVER = process.env.REACT_APP_LOCAL_SERVER
    const EMAIL = "test@test.com"

    const [chatSessionId, setChatSessionId] = useState(null);
    const [smessageId, setSmessageId] = useState(null); 
    const [displayButton, setDisplayButton] = useState(false);

    const resetSession = () => {
        console.log('resetSession')
        dispatch(clearData())

        // local state
        setChatSessionId(null)
        setSmessageId(null)
        setDisplayButton(false)
        setQuery('')
        setErrorMessage('')
        setSearchStatus(null)
    }


    // ================== get token =================

    // const userId = 'some-uid';
    const additionalClaims = {
        premiumAccount: true,
    };


    const converse = async (message) => {
        dispatch(addToDiscourse({ actor: 'user', message: message}))


        const response = await RestFunctions.query (message, chatSessionId)
        //console.log('response from local', response)

        // const response2 = await RestFunctions.gcpChat(message, chatSessionId)
        // console.log('secure response', response2)


        if (response) {
            //console.log('conversation response:', response)
            if (response.request_id) setChatSessionId(response.request_id)
            dispatch(addToDiscourse({ actor: 'ai', message: response.ai_response}))

            //todo:  this isn't returning when i run the proxy!
            //if (response.is_done) {

            console.log('discourse.length', discourse.length)
            if (discourse.length > 3) {
            console.log('SHOW BUTTON')
            setDisplayButton(true)
            }
        }
        return 
    }


    const getHistory = async (rId) => {
        if (!rId) rId = chatSessionId
        const response = await RestFunctions.getHistory(rId)
        console.log('getHistoryResponse', response)
        return response

    }

    const startSearch = async () => {
        dispatch(setSearching(true))
        const response = await RestFunctions.getHistory(chatSessionId)
        console.log('buyerry_request_history response:', response)
        if (response) {
            const message_id = response.messages[response.messages.length - 1].mesage_id
            setSmessageId(message_id)
            console.log('message_id', message_id)

            findProducts(message_id)
        } else console.log('ERROR: no response from request history')
    }


    

    const findProducts = async (message_id) => {

        const response = await RestFunctions.search(message_id)
        console.log('buyerry search response:', response)
        dispatch(setSearching(false))

        //todo: this should return the status, perhaps create a timer task...
        if (response) {
            getProducts(message_id)
        } else {
            setErrorMessage('Server returned error message during search...')
        }
    }   
    
    const publishProducts = (p) => {
        console.log('p', p)
        const rezults = p?.products?.map(e => {
            return { 
                images: e.medias,
                product_data: e.product_data,
                reviews: e.reviews,
                score: e.score
            }
        })
        console.log(rezults)
        //dispatch(setSearching(false))
        if (rezults) dispatch(setProducts(rezults))

    }

    const getProducts = async (messageId) => {

        const response = await RestFunctions.getProducts(messageId ? messageId : smessageId)
        console.log('buyerry getProducts response:', response)

        setProducts(response)

        // const rezults = response?.products?.map(e => {
        //     return { 
        //         images: e.medias,
        //         product_data: e.product_data,
        //         reviews: e.reviews,
        //         score: e.score
        //     }
        // })
        //setSearchStatus(rezults?.status ? true : false)
        // //dispatch(setSearching(false))
        // if (rezults) dispatch(setProducts(rezults))
    } 


    // every time something is typed
    const queryUpdate = (v) => {
        setQuery(v)
    }


    const handleSubmit = (e) => {
        //appendQuery(query)
        // setTimeout(function(){converse(query)}, 500)
        converse(query)
        e.preventDefault();
        setQuery('')
    }

    return (
        <div>
            { smessageId && searching && 
                <div>Searching...</div>
            }
            {errorMessage && <p style={{ color: 'red', fontWeight: 'bold' }}>{errorMessage}</p> }

            { products &&
                <Box>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ verticalAlign: 'top', height: '100%'}}> 
                     
                        <Box sx={{ backgroundColor: 'red', verticalAlign: 'top'}}>
                            <Grid
                                container
                                spacing={0}
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                                //sx={{ minHeight: '10vh' }}
                            >
                            <Grid item xs={3} sx={{ p: '2px', m: '2px' }}>
                                {discourse.map((item, index) => {
                                    return <ChatDialog chat={item} index={index} />
                                })}

                            </Grid>
                            <TextEntry query={query} queryUpdateHandler={queryUpdate} querySubmitHandler={handleSubmit} />
                            </Grid>   

                        </Box>


                        <Box sx={{ verticalAlign: 'top', textAlign: 'left'}}>
                            <Paper sx={{ flexGrow: 1, overflow: 'auto' }}>
                            { products.map((item, index) => {
                                return <ProductCard product={item}/> 
                            })}
                            </Paper>

                        </Box>
                    </Stack>
                </Box>
            }


            { !products &&
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                //sx={{ minHeight: '10vh' }}
            >
                <Grid item xs={3} sx={{ p: '2px', m: '2px' }}>
                    {discourse.map((item, index) => {
                        return <ChatDialog chat={item} index={index} />
                    })}

                    

                    {/* { !searching && !products &&
                    // <TextEntry query={} submitHandler={handleSubmit} queryUpdateHandler={queryUpdate} />
                    
                    } */}
                </Grid>
            </Grid>
            }


            {/* { displayButton && !searching && !products && */}
            <Grid 
                container 
                sx={{ my: 2, display: 'inline-flex', alignItems: 'right', width: 600 }}
                spacing={0}
                direction="column"
                justifyContent="right"
                alignItems="right">
                <div className='align-right'>
                { displayButton && !searching && !products && !errorMessage &&
                    <Button onClick={startSearch} variant="contained" color="primary" sx={{ backgroundColor: '#0096cd' }}>Search</Button>
                }
                &nbsp;&nbsp;
                { smessageId && searchStatus !== "COMPLETED" && (products || searching) && 
                    <Button onClick={() => getProducts()} variant="contained" color="primary" sx={{ backgroundColor: '#0096cd' }}>Update</Button>
                }
                &nbsp;&nbsp;
                {discourse.length > 0 &&
                    <Button onClick={() => resetSession()} variant="contained" color="primary" sx={{ backgroundColor: '#0096cd' }}>New Session</Button>
                }

                </div>
                
            </Grid>
            



            {/* --- SHOW PRODUCTS IN A TABLE --- */}

            {/* { products && 
                <div>
                    { products.map((item, index) => {
                        return <ProductCard product={item}/> 
                    })}
                </div>
            } */}


        {/* <Box component="div" sx={{ backgroundColor: 'red', alignItems: 'center', mx: '2px', transform: 'scale(0.8)', width: '100%', height: '100%'}}>

      </Box> */}


            
            <p>DEBUGGING INFORMATION</p>
            <p>local server { process.env.REACT_APP_LOCAL_SERVER ? 'yes (' + process.env.REACT_APP_LOCAL_SERVER + ')' : 'no'}</p>
            <p>Chat Session Id (RequestId:) {chatSessionId}</p>
            <p>Searching: {searching ? 'true' : 'false'}</p>
            <p>Search Status: {searchStatus}</p>
            <p>messageId: {smessageId}</p>
            <p>Products: {products?.length}</p>
            <Button onClick={RestFunctions.gcpOnboard}>Test GCP Onboard</Button>
            <Button onClick={RestFunctions.gcpChat}>Test GCP Chat</Button>
            <Button onClick={() => getHistory()}>getHistory</Button>
            <Button onClick={() => publishProducts(getProductData())}>Load Products</Button>

            <p>{ JSON.stringify(user) }</p> 
            
      </div>
    )
}

export {QueryPage}