import React, { useState, useEffect, useRef, useCallback, Pressable } from "react";
import Paper from "@mui/material/Paper";
import useMediaQuery from './useMediaQuery';

import Box from "@mui/material/Box";
import {
    Container,
    Grid,
    Tooltip,
    Divider,
    Card,
    Typography, Button,
} from "@mui/material/";

import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import SendIcon from '@mui/icons-material/Send';


import { queryAllByAltText } from "@testing-library/react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import {
    setChatSessionId,
    selectChatSessionId,
    setHistory,
    selectHistory,
    selectDiscourse,
    selectSearching,
    selectProducts,
    addToDiscourse,
    setSearching,
    setProducts,
    clearData,
    loadDiscourse, selectIsInside,
} from "../redux/dataSlice";
import {
    recordAnonSearch,
    selectUser,
    setUser,
    logout,
} from "../redux/identitySlice";
import { auth } from "../firebase";
import { ProductCard } from "../components/ProductCard";
import { DetailView } from "../components/DetailView";
import { DetailViewMob } from "../components/DetailViewMob";
import { ChatDialog } from "../components/ChatDialog";
import { TextEntry } from "../components/TextEntry";
import { DebugVertical } from "../components/DebugVertical";

import { HistorySelector } from "../components/HistorySelector";
import { DetailImageCarousel } from "../components/DetailImageCarousel";
import logo from "../assets/buyerry-icon.svg";

import { AuthDetails } from "../components/auth/AuthDetails";
import { SignIn } from "../components/auth/SignIn";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {CornerAvatar} from "../components/CornerAvatar";

const { RestFunctions } = require("../repositories/Network");
const { getProductData } = require("../samples/GetProducts");
const { getDiscourseData } = require("../samples/GetDiscourse");

function MainPage({ chatSessionId, conversationHandler, checkSignupRequired, userSignOut, isMobile }) {
    // const isMobile = useMediaQuery('(max-width: 768px)');

    const [showDebug, setShowDebug] = useState();
    const [showHistory, setShowHistory] = useState();

    const dispatch = useDispatch();
    const discourse = useSelector(selectDiscourse);
    const searching = useSelector(selectSearching);
    const products = useSelector(selectProducts);
    const history = useSelector(selectHistory);
    //const chatSessionId = useSelector(selectChatSessionId)

    const user = useSelector(selectUser);

    const [query, setQuery] = useState("");
    const [chatEntry, setChatEntry] = useState("");
    const [errorMessage, setErrorMessage] = useState();
    const [searchStatus, setSearchStatus] = useState(null);
    const [detailItem, setDetailItem] = useState();
    const [timerId, setTimerId] = useState("");

    //console.log('Connecting to server:', process.env.REACT_APP_LOCAL_SERVER, 'Version:', process.env.REACT_APP_VERSION)

    const SERVER = process.env.REACT_APP_LOCAL_SERVER;
    const EMAIL = "test@test.com";


    const [rightPanelVisible, setRightPanelVisible] = useState(!isMobile);
    const [leftPanelVisible, setLeftPanelVisible] = useState(!isMobile);
    const [detailedPanelVisible, setDetailedPanelVisible] = useState(false);

    //const [chatSessionId, setChatSessionId] = useState(null);
    const [smessageId, setSmessageId] = useState(null);

    const ref = useRef();

    const resetSession = () => {
        //console.log("MainPage: resetSession()");

        if (!checkSignupRequired()) {
            dispatch(clearData());
            dispatch(setChatSessionId(null));
            setSmessageId(null);
            setQuery("");
            setErrorMessage("");
            setSearchStatus(null);
            setDetailItem();
        }
    };

    // ================== get token =================

    const additionalClaims = {
        premiumAccount: true,
    };

    const updateDetailItem = (id) => {
        const i = products.find((e) => e.id === id);
        //console.log("updateDetailProduct:", i);
        if (i) setDetailItem(i);
    };


    const toggleRightPanel = () => {
        setRightPanelVisible(!rightPanelVisible);
        //console.log("right panel visible: ", rightPanelVisible);
    };

    const closeRightPanel = (e) => {
        if (isMobile) {
          setRightPanelVisible(false);
        } else {
          e.preventDefault();
          setRightPanelVisible(true);
        }
    };

    const toggleLeftPanel = () => {
        setLeftPanelVisible(!leftPanelVisible);
        //console.log("left panel visible: ", leftPanelVisible);
    };

    const openDetailedPanel = () => {
        setDetailedPanelVisible(true);
    };

    const closeDetailedPanel = () => {
        setDetailedPanelVisible(false);
    };

    useEffect(() => {
        if (!products || products.length === 0) {
            setDetailItem()
            return
        }
        //console.log("update detail item:", products[0].id);
        updateDetailItem(products[0].id);
        resetScroll();
    }, [products]);

    useEffect(() => {
        RestFunctions.gcpData({ target: "user_data" }).then((e) => {
            //console.log("user data", e);
            dispatch(setHistory(e.data));
        });
    }, []);

    useEffect((e) => {
        const handleResize = (e) => {
            setRightPanelVisible(true)
            if (isMobile) {
                // setRightPanelVisible(false);
            } else {
                setRightPanelVisible(true);
                if (!rightPanelVisible) {
                    setRightPanelVisible(true)
                }
            }
        };
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    useEffect(() => {
        if (!discourse) return;
        resetScroll();
        // console.log("is Mobile: ", isMobile);
        // console.log("is searching: ", searching);
        // if (isMobile) setRightPanelVisible(isMobile && searching);
        // console.log("right panel visible: ", isMobile && searching);
        if (isMobile) setLeftPanelVisible(false);
        //console.log("left panel visible: ", leftPanelVisible);
    }, [discourse, detailItem]);

    const startSearch = async () => {
        if (checkSignupRequired()) {
            //console.log("MainPage: startSearch: signup is required, aborting search");
            return;
        }

        if (isMobile){
            if (searching) {
                setRightPanelVisible(true);
            } else {
                setRightPanelVisible(false);
            }
        }

        dispatch(setSearching(true));
        const response = await RestFunctions.getHistory(chatSessionId);
        // console.log(
        //     "MainPage: startSearch: buyerry_request_history response:",
        //     response
        // );
        if (response) {
            const buyerryMessages = response.messages.filter(e => e.message_type === 'external');
            const message_id = buyerryMessages.length > 0 ? buyerryMessages[buyerryMessages.length - 1].mesage_id : null;
            setSmessageId(message_id);
            //console.log("MainPage: startSearch: response messages", response.messages);
            //console.log("Buyerry messages: ", buyerryMessages, buyerryMessages.length);
            //console.log("WARNING!!! No buyer messages: ", message_id)
            //console.log("MainPage: startSearch: message_id", message_id);
            dispatch(recordAnonSearch(chatSessionId));
            findProducts(message_id);
        } else console.log("ERROR: no response from request history");
    };

    const findProducts = async (message_id) => {
        //console.log('start timer search for products from message_id', message_id)
        startSearchStatus(message_id);

        // console.log(
        //     "MainPage: startSearch: start long search: message_id:",
        //     message_id
        // );
        const response = await RestFunctions.search(message_id);
        //console.log("MainPage: startSearch: buyerry search response:", response);
        dispatch(setSearching(false));

        //todo: this should return the status, perhaps create a timer task...
        if (response) {
            getProducts(message_id);

            RestFunctions.gcpData({ target: "user_data" }).then((e) => {
                //console.log("user data", e);
                dispatch(setHistory(e.data));
            });
        } else {
            setErrorMessage("Server returned error message during search...");
        }
    };

    const publishProducts = (p) => {
        //console.log("p", p);
        const rezults = p?.products?.map((e) => {
            return {
                images: e.medias,
                product_data: e.product_data,
                reviews: e.reviews,
                score: e.score,
            };
        });
        //console.log(rezults);
        //dispatch(setSearching(false))
        if (rezults) dispatch(setProducts(rezults));
    };

    const getProducts = async (messageId) => {
        const response = await RestFunctions.getProducts(messageId ? messageId : smessageId)
        //console.log('buyerry getProducts response:', response)
        // if (!response?.products || response.products.length === 0){
        //     console.log('getProducts did not return ANY products, skipping update FIX')
        // }

        publishProducts(response)
        //setProducts(response)
    };

    const _timer = { start: null };

    const getTimerId = () => {
        return _timer;
    };

    const startTimer = (i) => {
        const d = new Date();
        _timer.start = d.getTime();
        _timer.interval = i;
        //console.log("start timer", _timer);
    };

    const resetTimer = () => {
        //console.log("resetTimer", _timer);
        clearInterval(_timer.interval);
        _timer.start = null;
        _timer.interval = null;
    };

    const getProductSearchStatus = async (messageId) => {
        const response = await RestFunctions.gcpData({
            target: "search_products",
            message_id: messageId ? messageId : 1066,
        });
        console.log("================RESPONSE!!!!", response.data.products);
        if (response.data.products.length > 0) {
            //console.log("response", response.data);
            publishProducts(response.data);
            if (isMobile) {
                setRightPanelVisible(true);
            }
        }

        if (response.data.search_status === "COMPLETED") {
            resetTimer();
            return;
        }

        console.log(
            "==========timer========",
            new Date().getTime() - getTimerId()?.start
        );
        if (new Date().getTime() - getTimerId()?.start > 60000) {
            resetTimer();
        }
    };

    const startSearchStatus = (messageId) => {
        //startTimer()
        setTimeout(() => {
            startTimer(
                setInterval(() => {
                    // runs every 2 seconds
                    getProductSearchStatus(messageId);
                }, 2000)
            );
        }, 2000);
    };

    // every time something is typed
    const queryUpdate = (v) => {
        setQuery(v);
    };

    const handleSubmit = (e) => {
        //console.log('SUBMIT!', query)
        //converse(query)
        conversationHandler(query);

        e.preventDefault();
        setQuery("");
        resetScroll();
    };

    const [pos, setPos] = useState(false);

    const resetScroll = () => {
        //console.log('reset scroll!', ref.current)
        try {
            ref.current.scrollTop = 10000;
            // ref.current.scrollBottom = 0;
        } catch (ex) { }
    };

    const handleScroll = (e) => {
        // console.log("handleScroll", e);
        if (ref.current.scrollTop > 50) {
            if (!pos) setPos(true);
        } else {
            if (pos) setPos(false);
        }
    };

    useEffect(() => {
        // get the debug token from the url bar
        const urlSearchString = window.location.search;
        const params = new URLSearchParams(urlSearchString);
        const temp = ref.current;

        setShowDebug(params.get("debug"));
        setShowHistory(params.get("history"));

        // setup auto scroller
        temp.addEventListener("scroll", handleScroll);

        return () => temp.removeEventListener("scroll", handleScroll);
    });

    // const [top, setTop] = useState(0)
    // const [height, setHeight] = useState(0)
    // const refContainer = useRef(null)
    //
    //   useEffect(() => {
    //     setTop(refContainer.current.clientTop);
    //     setHeight(refContainer.current.clientHeight);
    //   })

    return (
            <>
                {/* <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={searching}
                    // onClick={handleClose}
                >
                    <CircularProgress color="inherit" />
                </Backdrop> */}

                <Container className="app" sx={{display: 'flex', flexDirection: 'row', overflow: 'hidden'}}>
                    {/* Left Panel */}
                    <CornerAvatar userSignOut = {userSignOut}/>
                    <Grid className={`left-panel ${!leftPanelVisible ? 'collapsed' : ''}`} sx={{backgroundColor: 'white'}}>
                        <Grid sx={{display: 'block', flexDirection: 'column', width: '100%'}}>
                            <Box sx={{ display: "block", height: "80px" }}>
                                <Card
                                    style={{
                                        width: '100%',
                                        cursor: 'pointer',
                                        boxShadow: 'none',
                                        margin: '10px',
                                        backgroundColor: 'unset',
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}
                                    onClick={() => resetSession()}
                                >

                                    <Box sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        flexDirection: 'column',
                                        width: "calc(100% - 20px)",
                                        marginRight: "10px"
                                    }}>
                                <img
                                    src={logo}
                                    className="App-logo-min"
                                    alt="logo"
                                    style={{height: "36px", marginTop: '8px'}}
                                    sx={{mb: '30px', height: "30px", pointerEvents: "none"}}
                                />
                                        { leftPanelVisible && (

                                        <Button className='newRequest' sx={{
                                            mt: 2,
                                            borderRadius: 100,
                                            minWidth: 0,
                                            boxShadow: 'none',
                                            backgroundColor: 'silver',
                                            '&.MuiButton-root:hover': {
                                                bgcolor: '#0096cd',
                                                boxShadow: 'none',
                                            }
                                        }} variant="contained" aria-label="search">
                                            New Request
                                        </Button>
                                        )}

                                        { !leftPanelVisible && (
                                        <>
                                        <Button className='newRequest' sx={{
                                            mt: 2,
                                            borderRadius: 100,
                                            minWidth: 0,
                                            boxShadow: 'none',
                                            backgroundColor: 'silver',
                                            '&.MuiButton-root:hover': {
                                                bgcolor: '#0096cd',
                                                boxShadow: 'none',
                                            }
                                        }} variant="contained" aria-label="search" endIcon={<AddCircleOutlinedIcon className='newRequestIcon'/>}>

                                        </Button>
                                        </>
                                        )}
                                    </Box>
                                </Card>
                                <br/>
                                {/* <Divider variant='fullwidth' /> */}
                            </Box>
                            <Box sx={{height: "calc(100vh - 100px)", overflow: "auto", }}>
                                <HistorySelector history={history} leftPanelVisible={leftPanelVisible}/>
                            </Box>
                        </Grid>
                        <Box sx={{height: "calc(100vh - 300px)", overflow: "auto", backgroundColor: 'red'}}>
                            {showDebug === "true" && (
                                <DebugVertical
                                    // chatSessionId={chatSessionId}
                                    searchStatus={searchStatus}
                                    messageId={smessageId}
                                    detailItem={detailItem}
                                    publishProducts={publishProducts}
                                    resetScroll={resetScroll}
                                />
                            )}
                        </Box>
                        {/*<Box sx={{ height: "100px" }}>*/}
                        {/*    <br />*/}
                        {/*    /!* <AuthDetails show={true} /> *!/*/}
                        {/*    /!* <SignIn /> *!/*/}
                        {/*</Box>*/}
                        <button className={'lpbutton'} onClick={toggleLeftPanel}>
                            {leftPanelVisible && (
                                <ArrowBackIosNewOutlinedIcon sx={{
                                    opacity: '0.2'
                                }}/>
                            )}
                            {!leftPanelVisible && (
                                <ArrowForwardIosOutlinedIcon sx={{
                                    opacity: '0.2'
                                }}/>
                            )}
                        </button>
                    </Grid>

                    {/* Middle Panel */}
                    <Grid className="main-content-new"
                          sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              borderRadius: '20px 0 0 20px',
                              mb: 4,
                              backgroundColor: '#f2f2f2',
                              overflow: 'hidden',
                          }}>

                        {/* Details Panel */}
                        <Grid className="detailed-panel" sx={{display: detailedPanelVisible ? 'block' : 'none'}}>
                            <IconButton
                                onClick={closeDetailedPanel}
                                type="button"
                                sx={{
                                    p: '10px',
                                    pt: 0,
                                    width: '60px',
                                    height: '32px',
                                    borderRadius: '0 0 20px 0',
                                    cursor: 'pointer'
                                }}
                                aria-label="send">
                                <CloseOutlinedIcon/>
                            </IconButton>
                            {detailItem && (
                                <Box
                                    sx={{
                                        mt: 0,
                                        ml: 1,
                                        mb: 1,
                                        minHeight: "100%",
                                        height: "100%",
                                        overflow: "hidden",
                                    }}
                                >
                                    <Grid container sx={{height: "80px", pl: "0"}}>
                                        <Grid item xs={12} sx={{
                                            fontSize: '18px',
                                            textAlign: 'left',
                                            color: '#adadad',
                                            pt: 5,
                                            pl: 3
                                        }}>
                                            Detailed View
                                        </Grid>
                                    </Grid>
                                    <DetailViewMob product={detailItem}/>
                                </Box>
                            )}
                        </Grid>

                        {/* Top Panel */}
                        <Grid className="top-panel" sx={{borderRadius: '20px 0 0 20px'}}>
                            {/* === START DETAIL VIEW === */}
                            {detailItem && (
                                <Box
                                    sx={{
                                        mt: 0,
                                        ml: 1,
                                        mb: 1,
                                        minHeight: "520px",
                                        height: "520px",
                                        overflow: "hidden",
                                    }}
                                >
                                    <Grid container sx={{height: "60px", pl: "0"}}>
                                        <Grid item xs={12} sx={{
                                            fontSize: '18px',
                                            textAlign: 'left',
                                            color: '#adadad',
                                            pt: 4,
                                            pl: 4
                                        }}>
                                            Detailed View
                                        </Grid>
                                    </Grid>
                                    <DetailView product={detailItem}/>
                                </Box>
                            )}
                        </Grid>

                        {/* Chat Panel */}

                        <Grid className="bottom-panel-container"
                            sx={{overflow: 'hidden', borderRadius: '20px 20px 0px 0px', display: 'flex', flexDirection: 'column'}}
                            // ref={ref}
                        >
                            {/* Chat Header */}
                            <Grid sx={{
                                // display: 'block',
                                position: 'relative',
                                // top: 10,
                                zIndex: '10000',
                                // TODO: added start
                                padding: "0"
                                // TODO: added end
                            }}>
                                <Grid
                                    className = 'chatHeader'
                                    sx={{
                                        fontSize: '18px',
                                        textAlign: 'left',
                                        color: '#adadad',
                                        pl: 4,
                                        pb: 4,
                                        mt: 0,
                                        // TODO: added start
                                        padding: "25px"
                                        // TODO: added end
                                    }}
                                >
                                    Chat Window
                                </Grid>
                            </Grid>

                            {/* Chat Body */}
                            <Grid className="chatBody"
                                sx={{overflow: 'scroll', borderRadius: '20px 20px 0px 0px', ml: 0, height: '100%', maxHeight: '100%'}}
                                ref={ref}
                            >
                                <Grid container sx={{pl: "0", display: 'flex', flexDirection: 'column', mt: 0}}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            height: "maxContent",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                flex: 1,
                                                backgroundColor: 'white',
                                                overflow: "scroll",
                                            }}
                                        >
                                            <Paper
                                                sx={{
                                                    alignContent: "flex-start !important",
                                                    boxShadow: 0,
                                                    backgroundColor: 'transparent',
                                                }}
                                            >
                                                <div class='chat' style={{marginTop: "auto", borderRadius: '20px'}}/>
                                                {discourse.map((item, index) => (
                                                    <ChatDialog
                                                        id={index}
                                                        chat={item}
                                                        index={index}
                                                        startSearchHandler={startSearch}
                                                        searching={searching}
                                                        showSearch={discourse?.length > 0 && !searching && !errorMessage}
                                                    />
                                                ))}
                                                    {/*{height} <br/>*/}
                                                    {/*{top}*/}
                                            </Paper>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>

                            {/* Chat Input */}
                            <Grid className='textEntry' sx={{ display: 'flex', flexDirection: 'row' }}>
                                <TextEntry
                                    sx={{m: 3}}
                                    style={{padding: '0', width: '100%'}}
                                    showSearch={discourse?.length > 1 && !searching && !errorMessage}
                                    discourse={discourse}
                                    query={query}
                                    queryUpdateHandler={queryUpdate}
                                    querySubmitHandler={handleSubmit}
                                    resetSession={resetSession} // this one is deprecated
                                    startSearchHandler={startSearch}
                                    toggleRightPanel={toggleRightPanel}
                                    searching={searching}
                                    closeRightPanel={closeRightPanel}
                                />
                            </Grid>
                        </Grid>

                    </Grid>

                    {/* Right Panel */}
                    <Grid className={`right-panel ${rightPanelVisible ? 'visible' : ''}`} sx={{mb: 2, mt: 2, mr: 2, borderRadius: '0 20px 20px 0', backgroundColor: '#f2f2f2', display: 'flex', flexDirection: 'column'}}>
                        {/*<button className='mobDetails' onClick={openDetailedPanel}>Open Detailed</button>*/}
                        <Grid container sx={{height: "100px", pl: "0"}}>
                            <Grid item
                                xs={12}
                                className = 'rpHeader'
                                sx={{
                                    fontSize: '18px',
                                    textAlign: 'left',
                                    color: '#adadad',
                                    pt: 4,
                                    pl: 4,
                                    pb: 4,
                                    height: '80px',
                                }}
                            >
                                Products Found: {products?.length}
                            </Grid>
                        </Grid>
                        <Box
                            sx={{
                                overflow: "auto",
                                borderRadius: '20px',
                            }}
                        >
                                <Grid sx={{pt: 2}}>
                                    {products?.map((item, index) => (
                                        <ProductCard
                                            product={item}
                                            updateDetail={updateDetailItem}
                                            openDetailedPanel={openDetailedPanel}
                                        />
                                    ))}
                                </Grid>
                        </Box>
                    </Grid>
                </Container>
            </>

    );
}

export { MainPage };
