import * as React from 'react';


import {Typography, Box, Button} from '@mui/material/';

import { useSelector, useDispatch } from 'react-redux';
import { selectChatSessionId, setChatSessionId, setHistory, selectHistory, selectDiscourse, selectSearching, selectProducts, addToDiscourse, setSearching, setProducts, clearData, loadDiscourse } from '../redux/dataSlice';
import { recordAnonSearch, selectUser, setUser, logout} from '../redux/identitySlice';

const {RestFunctions} = require('../repositories/Network')
const {getProductData} = require('../samples/GetProducts')
const {getDiscourseData} = require('../samples/GetDiscourse')


function DebugVertical({ searchStatus, messageId, detailItem, publishProducts, resetScroll}) {
    const dispatch = useDispatch()

    const discourse = useSelector(selectDiscourse)
    const searching = useSelector(selectSearching)
    const products = useSelector(selectProducts)
    const history = useSelector(selectHistory)
    const user = useSelector(selectUser)
    const chatSessionId = useSelector(selectChatSessionId)

    const getHistory = async (rId) => {
        if (!rId) rId = chatSessionId
        const response = await RestFunctions.getHistory(rId)
        console.log('getHistoryResponse', response)
        return response

    }



    return (
        <Box>
            <Typography sx={{fontSize: '12px'}}>
                <p>DEBUGGING INFORMATION</p>
                <p>local server { process.env.REACT_APP_LOCAL_SERVER ? 'yes (' + process.env.REACT_APP_LOCAL_SERVER + ')' : 'no'}</p>
                <p>Chat Session Id (RequestId:) {chatSessionId}</p>
                <p>Searching: {searching ? 'true' : 'false'}</p>
                <p>Search Status: {searchStatus}</p>
                <p>MessageId: {messageId}</p>
                <p>Products: {products?.length}</p>
                <p>DetailItem: {detailItem?.id}</p>
                <p>Discourse Length: {discourse?.length}</p>
                <p>User displayName: {user?.displayName ? user?.displayName : 'Anonymous'}</p>
                <p>User provider: {user?.providerId ? user?.providerId : 'Anonymous' }</p>
            </Typography>
            <Button onClick={RestFunctions.gcpOnboard}>Test GCP Onboard</Button>
            <Button onClick={RestFunctions.gcpAuth}>Test GCP Auth</Button>
            <Button onClick={() => RestFunctions.gcpData({ target: 'user_data'})}>Test GCP User Data</Button>
            <Button onClick={() => RestFunctions.gcpData({ target: 'search_products', message_id: 1066 })}>Test GCP Data Search - Message Id</Button>
            <Button onClick={RestFunctions.gcpChat}>Test GCP Chat</Button>
            <Button onClick={() => getHistory()}>getHistory</Button>
            <Button onClick={() => resetScroll()}>reset scroll</Button>

            <Button onClick={() => {
                dispatch(loadDiscourse(getDiscourseData()))
            }}>Load Discourse</Button>
            <Button onClick={() => publishProducts(getProductData())}>Load Products</Button>
        </Box>
        
    )
}

export {DebugVertical}