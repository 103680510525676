import { signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import React, { useState } from "react";
import { auth, googleProvider } from "../../firebase";
import {ReactComponent as GoogleSigninLogo} from '../../assets/google.svg';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser, setUser, logout} from '../../redux/identitySlice';
import { clearData} from '../../redux/dataSlice';



  


function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const user = useSelector(selectUser)
  const dispatch = useDispatch()



  const signInWithGoogle = async () => {
    try {
    await signInWithPopup(auth,googleProvider);
    } catch (err){
      console.error(err);
    }
  };

  // #fix? 4/29/24 ---------------------------
  const googleSignup = async () => {
    try {
      const z = await signInWithPopup(auth, googleProvider) //.then(result => console.log(result));
      console.log('signup', z.user)
      dispatch(setUser(z.user))
    } catch (error) {
      console.log(error);
    }
  };

  const gSignup = async () => {
    await googleSignup()
    dispatch(clearData())
  }
  // -----------------------------------------




  const signIn = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        console.log(userCredential);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
  <>

    {  user?.providerId !== 'firebase' &&
      <div className="sign-in-container">

{/* THIS IS THE USERNAME/PASSWORD CODE!!! </div> */}

        {/* <form onSubmit={signIn}>
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></input>
          <input
            type="password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></input>
          <button type="submit">Log In</button>
        </form> */}


        {/* <button onClick={signInWithGoogle}> Signin with google</button> */}
        <br />
        <div className="svgwrapper" onClick={gSignup}
        //{signInWithGoogle}
        >
          <GoogleSigninLogo />
        </div>


      </div>
    }
    </>
  );
};

export { SignIn }

