import { createSlice } from '@reduxjs/toolkit';  
const { generateUuid, } = require('../common/common')

const ANON_SEARCHES = 'anon-searches'

const getAnonSearchesFromStorage = () => {
  let  anonSearches = localStorage.getItem(ANON_SEARCHES) //, JSON.stringify({ authToken: result }))
  if (anonSearches) {
    anonSearches = JSON.parse(anonSearches)
  }
  //console.log("identitySlice: RETRIEVED ANON_SEARCHES: " + anonSearches)
  return anonSearches
}

// const BROWSER_ID = 'browser-id'

// const getAuthTokenFromStorage = () => {
//   let  credentials = localStorage.getItem(BROWSER_ID) //, JSON.stringify({ authToken: result }))
//   console.log("identitySlice: RETRIEVED LOCAL STORAGE (browser_id): " + credentials)

//   if (!credentials) {
//     credentials = generateUuid()
//     console.log("identitySlice: New credentials generated: " + credentials)
//     localStorage.setItem(BROWSER_ID, credentials)
//   }
//   return credentials
// }

// const anonymizeUser = () => {
//   const r = {
//     "displayName": "",
//     "email": getAuthTokenFromStorage() + "@buyerry.com",
//     "accessToken": null,
//     "photoURL": null,
//     "uid": null, 
//     "phoneNumber": null,
//     "providerId": "anonymous"            
//   }
//   console.log('identitySlice: anonymizeUser:', r)
//   return r
// }

export const identitySlice = createSlice({
  name: 'identity',
  initialState: {
    user: null, //anonymizeUser(),
    anonSearches: getAnonSearchesFromStorage(),


    // {
    //   "displayName": "Scott Douglas",
    //   "email": "sd@buyerry.com",
    //   "accessToken": "eyJhbGciOiJSUzI1NiIsImtpZCI6ImEyMzhkZDA0Y2JhYTU4MGIzMDRjODgxZTFjMDA4ZWMyOGZiYmFkZGMiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiU2NvdHQgRG91Z2xhcyIsInBpY3R1cmUiOiJodHRwczovL2xoMy5nb29nbGV1c2VyY29udGVudC5jb20vYS9BQ2c4b2NKM2JkTUJPdHVYUTd3TzYxbUNMbGZ5Rkt1eHhVZjdBOTlJaFhmeFRNRkU9czk2LWMiLCJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vYXB0LXJldGluYS00MDAyMTgiLCJhdWQiOiJhcHQtcmV0aW5hLTQwMDIxOCIsImF1dGhfdGltZSI6MTcxMzg2MDc2NCwidXNlcl9pZCI6Ilh0cllTVjBjc29VU2NEbzIydW93QURTbHNEWTIiLCJzdWIiOiJYdHJZU1YwY3NvVVNjRG8yMnVvd0FEU2xzRFkyIiwiaWF0IjoxNzE0MzkxNjcwLCJleHAiOjE3MTQzOTUyNzAsImVtYWlsIjoic2RAYnV5ZXJyeS5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJnb29nbGUuY29tIjpbIjExMDk4NDU5ODY1OTQxODk5MTc0MyJdLCJlbWFpbCI6WyJzZEBidXllcnJ5LmNvbSJdfSwic2lnbl9pbl9wcm92aWRlciI6Imdvb2dsZS5jb20ifX0.ltGWJ-76kfO7G5_lTwnxrZ2UPoHynf3k_3d8o4Okm2TvKMx_pPYmpyXZNguv6K1p3L7srefc698fJqfeMbGGr8ZlfIZ4ZA8PGCz6wiIcyAHJxTdwthGwQkn265xHjatSp4o70vyPfjllIR7Jrs9LpHQlxsY9JWIeKvnGr-VgKuPuzjiVG_ijB7YkOTJvPt4QLgry2UYRNzI1evAmKK21Biyhtu6rMTU1oGSVnI55liQGPOw7CXoUR0kbwfdZIySL_y1O_3lXAOqalRRuFDxzBxfxEsubnFh_38U6v0lvf2B_gXtql2HYu3t5MKgQzmIxpkvAO_x4jyef_zNjUqBh4w",
    //   "photoURL": "https://lh3.googleusercontent.com/a/ACg8ocJ3bdMBOtuXQ7wO61mCLlfyFKuxxUf7A99IhXfxTMFE=s96-c",
    //   "uid": "XtrYSV0csoUScDo22uowADSlsDY2",  -- short, firebase uuid
    //   "phoneNumber": null,
    //   "providerId": "firebase"
    // }


    //browserId: getAuthTokenFromStorage(),
  },
  reducers: {
    recordAnonSearch: (state, action) => {
      
      //console.log('identitySlice: recordAnonSearch', action.payload)
      if (state.user) {
        //console.log('identitySlice: recordAnonSearch:  user is not anonymous, exiting')
        return
      }

      let newSearches = JSON.parse(JSON.stringify(state.anonSearches));
      if (!newSearches) newSearches = []


      newSearches.push(action.payload)

      localStorage.setItem(ANON_SEARCHES, JSON.stringify(newSearches))
      //console.log('identitySlice: anonSearches', newSearches)

      return {
          ...state,
          anonSearches: newSearches, //anonymizeUser(),
      }
    },
    // setAuthToken: (state, action) => {
    //   const d = new Date();
    //   //console.log(action.payload)

    //   const payload = action.payload.data.AuthenticationResult
    //   d.setSeconds(d.getSeconds() + payload.ExpiresIn)
    //   payload.expiry = d.toString()

    //   //console.log(action.payload.config.data)
    //   //console.log(action.payload.config.data.username)
    //   payload.cognitoUser = JSON.parse(action.payload.config.data).username
    //   //console.log(payload.cognitoUser)
      
    //   //console.log('saving authToken to localStorage: ' + action.payload)
    //   //console.log(payload)
    //   const stringifiedToken = JSON.stringify({ authToken: payload })
    //   //console.log('stringified auth token: ' + stringifiedToken)

    //   localStorage.setItem(BROWSER_ID, stringifiedToken)
    //   //console.log("LOCAL STORAGE SAVING (credentials): " + stringifiedToken)
    //   state.authToken = payload

    // },
    //addAnonSearch:
    logoutIdentity: (state, action) => {
        //console.log('identitySlice: logout')
        return {
            ...state,
            user: null, //anonymizeUser(),
        }

    },
    setUser: (state, action) => {
        const payload = action.payload
        //console.log('identitySlice: setIdentity: action.payload', payload)

        const id = {
            displayName: payload.displayName,
            email: payload.email,
            accessToken: payload.accessToken,
            photoURL:  payload.photoURL,
            uid:  payload.uid,

            phoneNumber:  payload.phoneNumber,
            providerId:  payload.providerId,

        }

        //console.log('identitySlice: identitySlice setUser', id)
        return {
            ...state,
            user: id,
        }
    },





    // addColumn: (state, action) => {
    //   let newCols = [...state.columns];
    //   newCols = newCols.concat(action.payload);
    //   return {
    //     ...state,
    //     columns: newCols
    //   };
    // },
    // removeColumn: (state, action) => {
    //   let newCols = [...state.columns];
    //   newCols = newCols.filter(colDef => colDef.field !== action.payload);
    //   return {
    //     ...state,
    //     columns: newCols
    //   };
    // },
    // updateColumns: (state, action) => {
    //   let newCols = [...state.columns];
    //   newCols = newCols.map(col => {
    //     if (col.field == action.payload.field) {
    //       return action.payload;
    //     }
    //     return col;
    //   });
    //   return {
    //     ...state,
    //     columns: newCols
    //   };
    // }
  }
});

export const { setUser, logoutIdentity, recordAnonSearch } = identitySlice.actions;

export const selectUser = (state) => state.identity.user;
export const selectAnonSearches = (state) => state.identity.anonSearches;


//export const selectAuthToken = state => state.rows.bidAccuracy //used by main page
//export const authCredentials = (state) => state.identity.authCredentials;
//export const { addColumn, removeColumn, updateColumns } = columnSlice.actions;
//export const selectColumns = state => state.columns.columns;
export default identitySlice.reducer;