import * as React from 'react';

import {InputBase, Paper, Divider, Box, IconButton, LinearProgress} from '@mui/material/'
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardVoice from '@mui/icons-material/KeyboardVoice';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SavedSearchOutlinedIcon from '@mui/icons-material/SavedSearchOutlined';

function TextEntry({showSearch, discourse, query, queryUpdateHandler, querySubmitHandler, resetSession, startSearchHandler, toggleRightPanel, searching, closeRightPanel}) {
    //console.log('text entry props', query)
    const inputRef = React.useRef(null);

    const handleSearchClick = () => {
        if (inputRef.current) {
            queryUpdateHandler(inputRef.current.value);
            alert(inputRef.current.value)
        }
        alert(inputRef.current)
        // startSearchHandler();
    };

    return (
        <Paper className='textEntry' elevation={0} sx={{ textAlign: 'left', ml: 0, mb: 0, mt: 0, mr: 0, boxShadow: '0 8px 20px 0px #dddddd', borderRadius: '0 0 20px 20px', width: '100%'}} component="form" onSubmit={querySubmitHandler}>
            <Divider variant='middle' sx={{
                margin: 0,
            }} />
            { searching &&
                <>
                    <LinearProgress sx={{
                        width: '100%',
                        margin: 0,
                        ml: 0,
                        height: '2px',
                        opacity: 0.8,
                        marginTop: '-1px'
                    }} color="primary"/>
                </>
            }
            <Box sx={{ display: 'flex'}}>
                <InputBase
                    sx={{ ml: 0, pl: 3, mb: 0, flex: 1, width: '100%', height: '60px'}}
                    placeholder={ !searching ? 'Find you what you need' : 'Searching...' }
                    inputProps={{ 'aria-label': 'search for what you need' }}
                    value={ query }
                    onChange={(e) => queryUpdateHandler(e.target.value)}
                    onClick={closeRightPanel}
                    className='InputField'
                />

                { searching &&
                    <Box sx={{ width: '100%' }}>
                    </Box>
                }
                { showSearch &&
                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                        <IconButton
                            onClick={toggleRightPanel}
                            className='SavedSearch'
                            type="button"
                            sx={{
                                p: '10px',
                                width: '60px',
                                height: '60px',
                                borderRadius: '0'
                            }}
                            aria-label="send">
                            <SavedSearchOutlinedIcon/>
                        </IconButton>
                        <IconButton
                            onClick={querySubmitHandler}
                            type="button"
                            sx={{
                                p: '10px',
                                width: '60px',
                                height: '60px',
                                borderRadius: '0 0 20px 0'
                            }}
                            aria-label="send">
                            <ArrowForwardIosIcon/>
                        </IconButton>
                        {/*<button onClick={} className="bpbutton">SEARCH</button>*/}
                    </Box>
                }
                {/* {discourse?.length > 0 &&
                    <IconButton onClick={() => resetSession()} type="button" sx={{ p: '10px' }} aria-label="search">
                        <ClearIcon />
                    </IconButton>
                } */}
            </Box>

        </Paper>

        // <Paper
        //     component="form"
        //     onSubmit={querySubmitHandler}
        //     sx={{ m: '2px', p: '4px', 
        //         display: 'inline-flex', alignItems: 'center' }}>
        //     <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
        //     <SearchIcon />
        //     </IconButton>
        //     <InputBase
        //         sx={{ ml: 1, flex: 1 }}
        //         placeholder="Find you what you need"
        //         inputProps={{ 'aria-label': 'search for what you need' }}
        //         value={query}
        //         onChange={(e) => queryUpdateHandler(e.target.value)}
        //     />

        //     <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
        //     <Fab size="small" color="secondary" aria-label="add" sx={{ p: '10px', backgroundColor: '#0096cd' }}>
        //     <KeyboardVoice />
        //     </Fab>
        // </Paper>
    )
}

export {TextEntry}