import React, { useState, useEffect, useRef, useCallback } from 'react';

import logo from './assets/buyerry.svg';
import './App.css';
import { QueryPage } from './pages/QueryPage'
import { SignIn } from './components/auth/SignIn'
import { SignUp } from './components/auth/SignUp'
import { AuthDetails } from './components/auth/AuthDetails'
import { useSelector, useDispatch } from 'react-redux';
import { selectAnonSearches, selectUser, setUser, logout} from './redux/identitySlice';
import { Avatar, Box, Container, Grid, Stack, Button, Backdrop, Divider, Card, CircularProgress, Typography, Rating} from '@mui/material/';
import { setInside, selectChatSessionId, selectIsInside, setHistory, selectHistory, selectDiscourse, selectSearching, selectProducts, addToDiscourse, setSearching, setProducts, clearData, loadDiscourse, setChatSessionId } from './redux/dataSlice';
import { MainPage } from './pages/MainPage';
import { TextFront } from './components/TextFront';
import { CornerAvatar } from './components/CornerAvatar';
import { SignInDialog } from './components/SignInDialog';
import useMediaQuery from './pages/useMediaQuery';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "./firebase";


//import theme from "./theme";
//import { ThemeProvider } from "@material-ui/styles";

import { ThemeProvider, createTheme } from '@mui/material/styles';

const {RestFunctions} = require('./repositories/Network')

const theme = createTheme({
  // palette: {
  //   primary: {
  //     main: red[500],
  //   },
  // },
  typography: {
    fontFamily: [
        ['SF Pro Display', 'sans-serif'],
        'Roboto',
        'Raleway',
        'Open Sans',
    ].join(','),
    h1: {
        fontSize: '5rem',
        fontFamily: 'Raleway',
    },
    h2: {
        fontSize: '3.5rem',
        fontFamily: 'Open Sans',
        fontStyle: 'bold',
    },
    h3: {
        fontSize: '2.5rem',
        fontFamily: 'Roboto',
    },
},
});



function App() {
  const user = useSelector(selectUser)
  const isInside = useSelector(selectIsInside)
  const isMobile = useMediaQuery('(max-width: 768px)')
  const chatSessionId = useSelector(selectChatSessionId)
  const anonSearches = useSelector(selectAnonSearches)

  const [querying, setQuerying] = useState(false);

  const dispatch = useDispatch()



  const converse = async (message) => {
    if (checkSignupRequired()) return
    if (message === '') return

    setQuerying(true)


    //console.log('App.js:  message', message, chatSessionId)
    dispatch(addToDiscourse({ actor: 'user', message: message}))

    const response = await RestFunctions.query (message, chatSessionId)
    //console.log('App.js:  query response======', response)
    if (response) {
        if (response.request_id) {
          //console.log('===SETTING CHAT SESSION ID===', response.request_id)
          dispatch(setChatSessionId(response.request_id))
        }
        dispatch(addToDiscourse({ actor: 'ai', message: response.ai_response}))
        dispatch(setInside(true))
    }
  }

  const checkSignupRequired = () => {
    //console.log('App.js: checkSignupRequired:', user?.email)
    //console.log('App.js: checkSignupRequired: user logged in:', user ? 'true': 'false', 'anonySearches', anonSearches?.length )
    if (!user && anonSearches?.length > 1) {
      setOpenDialog(true)
      //console.log('App.js: checkSignupRequired: return true')
      return true
    }
    //console.log('App.js: checkSignupRequired: return false')
    return false
  }

  const userSignOut = () => {
    signOut(auth)
      .then(() => {
        console.log("AuthDetails: sign out successful");
        dispatch(clearData(true))
      })
      .catch((error) => console.log(error));
  };

  const [openDialog, setOpenDialog] = React.useState(false);
  const handleClickOpenDialog = () => {
      setOpenDialog(true);
  }
  const handleCloseDialog = () => {
      setOpenDialog(false);
  }

  return (

    <div className="App" style={{ 
      // TODO: changed to Dvh
      height: '100dvh', 
      color: 'black', 
      backgroundColor: `${isInside ? 'white' : '#f7f7f7'}`,
      fontSize: 14,
    // fontFamily: 'SF Pro Text',
    fontFamily: 'SF Pro Display'}}>


      <ThemeProvider theme={theme}>

      {!user &&
        <SignInDialog 
          message='You have been granted 2 anonymous searches.  If you wish to continue your journey, you must now sign in.' 
          openDialog={openDialog} 
          handleCloseDialog={handleCloseDialog} 
        />
      }
      {/* <SignIn /><SignUp /> */}

      <AuthDetails show={false} />


      { user?.accessToken || isInside ?
        // <QueryPage/>
        <>
          <MainPage 
            chatSessionId = {chatSessionId}
            conversationHandler = {converse}
            checkSignupRequired = {checkSignupRequired}
            userSignOut = {userSignOut}
            isMobile = {isMobile}
          />
        </>

      :
        <>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={querying}
                // onClick={handleClose}
            >
            <CircularProgress color="inherit" />
          </Backdrop>
        <Box
              sx={{
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'top',
                backgroundColor: 'rgb(247, 247, 247)',
                textAlign: 'center',
              }}>

            <Box
                // sx = {{
                //     position: 'relative',
                //     // left: 'calc(50vw - 300px)',
                //     // top: 'calc(50vh - 325px)',
                //     maxWidth: 'calc(100vw - 50px)'
                // }}
                sx={{
                    maxWidth: '600px',
                    minWidth: '80%',
                    padding: '30px',
                    display: 'ruby',
                    // mt: '-25vh',
                    alignContent: 'center'
                }}

            >
              <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
              </header>
              <Box sx = {{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                }}>

                <Box sx = {{
                    width: '100%',
                    mt: 6,
                }}>
                    <TextFront converseHandler = {converse} />



                    {/* <Box sx={{  m: 1, p: '0px', pt: 3 }}>
                    <Accordion
                      //defaultExpanded
                      sx={{ backgroundColor: '#F7F7F7'}}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <Typography>Popular items</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                          malesuada lacus ex, sit amet blandit leo lobortis eget.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{   backgroundColor: '#F7F7F7'}}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>Popular requests</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                          malesuada lacus ex, sit amet blandit leo lobortis eget.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    </Box> */}
                </Box>
              </Box>
              <br/><br/><br/>
              <SignIn />
              {/* {JSON.stringify(user)} */}
              </Box>
            </Box>
        </>
      }

      </ThemeProvider>
    </div>
  );
}

export default App;
