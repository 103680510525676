import axios from "axios";
// import { useSelector, useDispatch } from 'react-redux';
// import { selectDiscourse, selectSearching, selectProducts, addToDiscourse, setSearching, setProducts, clearData } from '../redux/dataSlice';
// import { selectUser, setUser, logout} from '../redux/identitySlice';
import { auth } from "../firebase";








const retrieveToken = async () => {
    try {
        const token = await auth.currentUser.getIdToken(true)  //force refresh
        return token
    } catch (err) {
        console.log('Network: Error: Could not retrieve an auth token for current user');

    }
    return null
}
 
const LOCAL_EMAIL = 'sd@buyerry.com'
const GLOBAL_PROTOCOL = 'http'
const SERVER_IP = process.env.REACT_APP_LOCAL_SERVER
const SERVER_URL = GLOBAL_PROTOCOL + '://' + SERVER_IP
const GCP_SERVER_URL = 'https://us-central1-apt-retina-400218.cloudfunctions.net'


const connections = {
    chat: {
        url: process.env.REACT_APP_LOCAL_SERVER ? SERVER_URL : GCP_SERVER_URL,
        operation: 'post',
        endpoint: process.env.REACT_APP_LOCAL_SERVER ? '/talk_to_buyerry' : '/buyerry-mvp-chat',
    },
    history: {
        url: process.env.REACT_APP_LOCAL_SERVER ? SERVER_URL : GCP_SERVER_URL,
        operation: process.env.REACT_APP_LOCAL_SERVER ? 'put' : 'post',
        endpoint: process.env.REACT_APP_LOCAL_SERVER ? '/buyerry_request_history' : '/buyerry-mvp-chat',
    },
    search: {
        url: process.env.REACT_APP_LOCAL_SERVER ? SERVER_URL : GCP_SERVER_URL,
        operation: 'post',
        endpoint: process.env.REACT_APP_LOCAL_SERVER ? '/find_products' : '/buyerry-mvp-chat',
    },
    products: {
        url: process.env.REACT_APP_LOCAL_SERVER ? SERVER_URL : GCP_SERVER_URL,
        operation: process.env.REACT_APP_LOCAL_SERVER ? 'put' : 'post',
        endpoint: process.env.REACT_APP_LOCAL_SERVER ? '/get_products' : '/buyerry-mvp-chat',
    },


    // these are gonna go by by due to integration
    gcpOnboard: {
        url: 'https://us-central1-apt-retina-400218.cloudfunctions.net',
        operation: 'post',
        endpoint: '/buyerry-mvp-onboard'
    },
    gcpAuth: {
        url: 'https://us-central1-apt-retina-400218.cloudfunctions.net',
        operation: 'post',
        endpoint: '/buyerry-mvp-auth'
    },
    gcpData: {
        url: 'https://us-central1-apt-retina-400218.cloudfunctions.net',
        operation: 'post',
        endpoint: '/buyerry-mvp-data'
    },
    gcpChat: {
        url: 'https://us-central1-apt-retina-400218.cloudfunctions.net',
        operation: 'post',
        endpoint: '/buyerry-mvp-chat'
    },
}

//console.log('network api config', connections)

const chat = async (message, requestId) => {
    const payload = {
        "message": message, 
        //"debug_option": true,  // true works, but 'true' does not
        //"email": auth.currentUser.email,
        // "firebaseUID": auth.currentUser.uid
        //"log_option": false,
    }

    if (requestId) payload.request_id = requestId

    if (!process.env.REACT_APP_LOCAL_SERVER) {  // if we are NOT running locally
        payload.target = 'chat'
    } else {
        payload.email =  LOCAL_EMAIL // as opposed to:  auth.currentUser.email
    }

    //console.log('CHAT!!!!!!!', payload, connections.history)

    const response = await transmit(connections.chat, payload)
    //console.log('Network: getChat request/response', { request: payload, response: response?.data})
    //console.log('query response', response?.data ? response?.data : response)
    return response?.data ? response?.data : response
}

const getHistory = async (chatSessionId) => {
    if (!chatSessionId) throw new Error('getHistory requires chatSessionId arg')
    const payload = { "request_id": chatSessionId, }

    if (!process.env.REACT_APP_LOCAL_SERVER) {  // if we are NOT running locally
        payload.target = 'history'
    } else {
        payload.email =  LOCAL_EMAIL // as opposed to:  auth.currentUser.email
    }

    //console.log('getHistory', payload, connections.history)

    const response = await transmit(connections.history, payload)
    //console.log('Network: getHistory request/response', { request: payload, response: response?.data}) //?.data ? response?.data : response)
    return response?.data ? response?.data : response
}

// history response direct to server:
// {
//     is_done: false,
//     messages: [
//         {
//             mesage_id: 494,
//             message_body: 'im looking for a sewing machine',
//             message_type: 'buyer',
//             created_at: "Sat, 16 Mar..."
//         },
//         {
//             mesage_id: 495,
//             message_body: '1. What kind of sewing projects do you have in mind?...',
//             message_type: 'external',
//             created_at: "Sat, 16 Mar..."
//         },
//     ],
// }
// proxy is exactly the same

const search = async (messageId) => {
    const payload = { "message_id": messageId, }

    if (!process.env.REACT_APP_LOCAL_SERVER) {  // if we are NOT running locally
        payload.target = 'search'
    } else {
        payload.email =  LOCAL_EMAIL // as opposed to:  auth.currentUser.email
    }

    //console.log('Network: ==> BEGIN SEARCH ==>', payload, connections.search)
    const response = await transmit(connections.search, payload)
    //console.log('search response from transmit:', response)
    //console.log('Network: <== SEARCH COMPLETE <==', response) //?.data ? response?.data : response)
    return response?.data ? response?.data : response
} 



const getProducts = async (messageId) => {
    const payload = { "message_id": messageId, }

    if (!process.env.REACT_APP_LOCAL_SERVER) {  // if we are NOT running locally
        payload.target = 'products'
    } else {
        payload.email =  LOCAL_EMAIL // as opposed to:  auth.currentUser.email
    }

    //console.log('getProducts', payload)

    const response = await transmit(connections.products, payload)
    //console.log('Network: getProducts request/response', { request: payload, response: response?.data})
    //console.log('getProducts response from transmit:', response)
    return response?.data ? response.data : response
}
 

const gcpOnboard = async (userData) => {
    const payload = {
        "message": "called from the ui!",
        "otherField": 8675309,
        "first": "Bob",
        //"debug_option": true,  // true works, but 'true' does not

        "log_option": false
    }

    //console.log('gcpOnboard request', payload)

    const response = await transmit(connections.gcpOnboard, payload)
    //console.log('Network: gcpOnboard request/response', { request: payload, response: response?.data})

    //console.log('gcpOnboard response', response?.data)

    return response
} 
const gcpAuth = async (userData) => {
    const payload = {
        "message": "calling gcp auth",
        "otherField": 8675309,
        "first": "Bob",
        //"debug_option": true,  // true works, but 'true' does not

        "log_option": false
    }
    //console.log('Network: gcpAuth request', payload)
    const response = await transmit(connections.gcpAuth, payload)
    //console.log('Network: gcpAuth request/response', { request: payload, response: response?.data})
    //console.log('Network: gcpAuth response', response?.data)
    return response
} 





// accepts:
//  { target: user_data } OR EMPTY JSON, NO DATA
//  { target: search_products, message_id: 1066}
//  user_searches
//  products_media
const gcpData = async (data) => {
    const payload = {
        ...data,
        //"message": "calling gcp data",
        //"otherField": 8675309,
        //"first": "Bob",
        //"debug_option": true,  // true works, but 'true' does not

        "log_option": false
    }

    const response = await transmit(connections.gcpData, payload)
    //console.log('Network: gcpData request/response', { request: payload, response: response?.data})

    return response
} 

const secureChat = async (message, requestId) => {
    const payload = {
        "message": message ? message : 'im looking for a new vacuum cleaner',
        //"log_option": false,
    }
    if (requestId) payload.request_id = requestId


    const response = await transmit(connections.gcpChat, payload)
    //console.log('Network: gcpChat request/response', { request: payload, response: response?.data ? response?.data : response})

    return response?.data ? response?.data : response
} 




export const RestFunctions = {
    query: chat,
    getHistory: getHistory,
    search: search,
    getProducts: getProducts,
    gcpOnboard: gcpOnboard,
    gcpAuth: gcpAuth,
    gcpData: gcpData,
    gcpChat: secureChat,
}












const transmit = async (connection, payload) => {

    const token = await retrieveToken()
    //console.log('auth token retrieved:', token)

    //todo:  check for valid token? it will error out anyway, for now...

    const url = connection.url + connection.endpoint

    //console.log('Network: transmitting', url, payload, connection.operation)

    try {
        const response = await axios({
            method: connection.operation,
            url: url,
            data: payload,
            headers: {
                "Authorization": token,
                "Content-Type": "application/json", 
                "Accept": "application/json"
                }
        })
        return response.data
    } catch (err) {
        console.log('Transmit Error:', err)
        return { error: err?.response?.data ? err?.response?.data : err?.response}

    }
}


