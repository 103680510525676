import React, { useState, useEffect, useCallback } from 'react';
import {Container, Grid, Stack, Button, Backdrop, Divider, Card, CircularProgress, Typography, ImageList, ImageListItem} from '@mui/material/';

function DetailImageCarousel({detailItem, handlePickPhoto}) { //handlePickPhoto

    // const cToken = useSelector(authToken);
    // const dispatch =  useDispatch();

    // useEffect(() => {
    //     console.log("ctoken updated!!!")
    // }, [cToken])

    return (
        <ImageList sx={{ px: '0px' }} cols={detailItem?.images?.length} rowHeight={50} >
            {detailItem?.images.map((item, idx) => (
                <ImageListItem key={idx} sx={{paddingRight: '3px'}}>
                <img style={{width: '50px', maxHeight: '50px'}}
                    onClick={() => handlePickPhoto(item)}
                    // srcSet={`${item}?w=100&h=100&fit=crop&auto=format&dpr=2 2x`}
                    // src={`${item}?w=100&h=100&fit=crop&auto=format`}
                    src={item}
                    alt={detailItem?.product_data?.title}
                    loading="lazy"
                />
                </ImageListItem>
            ))}
        </ImageList>

    )
}

export {DetailImageCarousel}