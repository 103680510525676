import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';

//import { SignIn } from './auth/SignIn'
//import { SignUp } from './auth/SignUp'
//import { AuthDetails } from './components/auth/AuthDetails'


import { selectUser, setUser, logout} from '../redux/identitySlice';
import { setInside, selectIsInside, } from '../redux/dataSlice';
//import { Avatar, Box, Container, Stack, Backdrop, Divider, CircularProgress, Typography, Rating} from '@mui/material/';
import {Avatar, Box, Menu, MenuItem} from '@mui/material'
import PersonIcon from '@mui/icons-material/Person';
import {Divider} from "@mui/material/";

const {AuthFunctions} = require('../repositories/Auth')


  



function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }
  
  function stringAvatar(name) {
  
    let splitter = null
    try {
      splitter = name.split(' ')[0][0] + name.split(' ')[1][0]
    } catch (ex) {
      //console.log('splitter error XXXXXX', ex)
      //console.log('CornerAvatar: name could not be split:', name)
      return {
        sx: {
          bgcolor: '#AAAAAA',
        },
        children: <PersonIcon sx = {{ color: 'white' }} />
      }

    }
  
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: splitter,
    };
  }

function CornerAvatar({userSignOut}) {

    const user = useSelector(selectUser)
    const isInside = useSelector(selectIsInside)
    const dispatch = useDispatch()

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const menuSelect = (action) => {
      setAnchorEl(null);
      if (action === 'logout') {
        //userSignOut()
        AuthFunctions.googleSignOut()
      }
      if (action === 'login') {
        AuthFunctions.googleSignInWithPopup()
      }
    };


    return (
        <>
                {/* <Box sx = {{ position: 'fixed', left: 'calc(100vw - 70px)', top: '19px' }}> */}
                <Box sx = {{ position: 'fixed', left: '20px', bottom: '20px', zIndex: '200000' }}>
                    <Avatar {...stringAvatar(user?.displayName)} 
                        onClick = { handleClick}
                        sx = {{
                            cursor: 'pointer'
                        }}
                    >  
                    </Avatar>
                      

                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={() => menuSelect('close')}
                        MenuListProps={{
                        'aria-labelledby': 'basic-button',
                        }}
                        sx = {{
                            left: '60px',
                            top: '-20px'
                        }}
                    >
                        {/* <MenuItem onClick={() => menuSelect('profile')}>Profile</MenuItem>
                        <MenuItem onClick={() => menuSelect('account')}>My account</MenuItem> */}
                        {/* <MenuItem onClick={() => menuSelect('logout')}>{user?.displayName?.length}</MenuItem>
                        <MenuItem onClick={() => menuSelect('logout')}>{user?.displayName}</MenuItem> */}

                        { user?.displayName?.length > 0 
                          ? <>
                                <MenuItem sx = {{minWidth: '200px', pointerEvents: 'none', color: 'silver'}}><PersonIcon sx={{marginRight: 1}}></PersonIcon>Profile</MenuItem>
                                <MenuItem sx = {{minWidth: '200px', pointerEvents: 'none', color: 'silver'}}><SettingsIcon sx={{marginRight: 1}}></SettingsIcon>Settings</MenuItem>
                                <Divider />
                                <MenuItem onClick={() => menuSelect('logout')}
                                sx = {{
                                    minWidth: '200px',
                                }}>
                                    <LogoutIcon sx={{marginRight: 1}}></LogoutIcon>Logout
                                </MenuItem>
                            </>
                          : <MenuItem onClick={() => menuSelect('login')}>
                                <LoginIcon sx={{marginRight: 1}}></LoginIcon>Login
                            </MenuItem>
                        }
                    </Menu>
                </Box>
        </>
    )
}

export {CornerAvatar}