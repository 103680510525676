const { v4: uuidv4 } = require('uuid')
//const short = require('short-uuid')
//const uuid = short()

//console.log('uuid', uuid)

const generateUuid = () => {
    return uuidv4()
}

exports.generateUuid = generateUuid