import { onAuthStateChanged, signOut } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { auth } from "../../firebase";
import { useSelector, useDispatch } from 'react-redux';
import { selectUser, setUser, logoutIdentity} from '../../redux/identitySlice';
import { setHistory, clearData} from '../../redux/dataSlice';
import {Button, Typography, Avatar} from '@mui/material/';


const {RestFunctions} = require('../../repositories/Network')


const AuthDetails = ({show}) => {
  //console.log('AuthDetails ============================= begin')
  const [authUser, setAuthUser] = useState(null);

  const dispatch = useDispatch()
  const user = useSelector(selectUser)

  useEffect(() => {
    const listen = onAuthStateChanged(auth, (user) => {
      
      if (user) {
        //console.log('AuthDetails: user auth state changed:', user)
        // check:         email   google
        // accessToken      o     o
        // displayName      x
        // email            o
        // emailVerified    f
        // phoneNumber      x
        // photoUrl         x
        // uuid             o

        dispatch(setUser(user))
        RestFunctions.gcpData().then(e => {
          //console.log('AuthDetails: user data', e)
          dispatch(setHistory(e.data))
        })

        setAuthUser(user);  //local

      } else {
        //console.log('AuthDetails:  calling logoutIdentity:')
        dispatch(logoutIdentity())
        setAuthUser(null);  //local
      }
    });



    return () => {
      listen();
    };
  }, []);

  const userSignOut = () => {
    signOut(auth)
      .then(() => {
        //console.log("AuthDetails: sign out successful");
        dispatch(clearData())
      })
      .catch((error) => console.log(error));
  };

  return (
    <div>
      {authUser && show ? (
        <>
          {/* <p>{`Signed In as ${authUser.email}`}&nbsp;&nbsp; */}
          <Typography variant='subtitle2'>{authUser.email}</Typography>
          <Button onClick={userSignOut}>Sign Out</Button>
        </>
      ) : ( <></>
        // <p>Signed Out</p>
      )}
    </div>
  );
};

export { AuthDetails }