import React, { useState, useEffect, useRef, useCallback } from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Fab from '@mui/material/Fab';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search'; 
import KeyboardVoice from '@mui/icons-material/KeyboardVoice';
import ClearIcon from '@mui/icons-material/Clear';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';

function TextFront({converseHandler}) {
    
    // const cToken = useSelector(authToken);
    // const dispatch =  useDispatch();

    // useEffect(() => {
    //     console.log("ctoken updated!!!")
    // }, [cToken])
    const [query, setQuery] = useState('');

    const querySubmitHandler = (e) => {
        //console.log('TextFront: outside text submit ===', e)
        converseHandler(query)
        e.preventDefault()
    }

    const queryUpdate = (v) => {
        setQuery(v)
    }

    const queryUpdateHandler = () => {
        console.log('GOTO INSIDE!!!')

    }

    return (
        <Paper  elevation={0} sx={{
            m: -1,
            p: '10px',
            boxShadow: '0 0 100px 1px #dddddd',
            width: '100%',
            maxWidth: '600px',
            backgroundColor: 'white',
            borderRadius: "20px"
        }}
                component="form"
                onSubmit={querySubmitHandler}
        >

            {/* <Divider variant='middle' /> */}
            <Box sx={{ display: 'flex',}}>
                <IconButton type="button" sx={{ p: '5px' }} aria-label="search">
                    <SearchIcon />
                </IconButton>

                <InputBase
                    sx={{ ml: 1, flex: 1 , maxWidth: '600px', width: '80%'}}
                    placeholder="Find you what you need"
                    inputProps={{ 'aria-label': 'search for what you need' }}
                    value={query}
                    onChange={(e) => queryUpdate(e.target.value)}
                />

                <IconButton type="button" sx={{ p: '5px' }} aria-label="search" onClick={querySubmitHandler}>
                    <ArrowForwardIosOutlinedIcon />
                </IconButton>

                {/* {showSearch &&
                    <IconButton onClick={startSearchHandler} type="button" sx={{ p: '10px' }} aria-label="search">
                        <SearchIcon />
                    </IconButton>
                } */}
                {/* {discourse?.length > 0 &&
                    <IconButton onClick={() => resetSession()} type="button" sx={{ p: '10px' }} aria-label="search">
                        <ClearIcon />
                    </IconButton>
                } */}
            </Box>
        </Paper>
    )
}

export {TextFront}