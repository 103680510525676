import React, { useState, useEffect, useCallback } from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import {Badge, Button, Typography, Rating, ImageList, ImageListItem, Paper, Link} from '@mui/material/';
import { DetailImageCarousel } from './DetailImageCarousel'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import {decode} from 'html-entities';

function DetailView({product}) {

    const [detailPhoto, setDetailPhoto] = useState(null);

    useEffect(() => {
        if (!product?.images) return
        setDetailPhoto(product.images[0])

        console.log('detailview product:', product?.product_data)
    }, [product])

    const handlePickPhoto = (item) => {
        setDetailPhoto(item)
    }

    return (
        <>

        { product &&
        <Stack direction="row" spacing={1}  sx={{ height: '100%', borderRadius: '20px 0 0 0', mb: 1}}>

            <Box sx={{width: '300px', height: '400px', p: 2, pt: 1}}>
                <Box>

                    { detailPhoto &&
                        <img 
                            style={{maxWidth: '300px', maxHeight: '300px', minHeight: '300px', borderRadius: '20px', mt: 0}}
                            src={detailPhoto} />
                    }
                    <DetailImageCarousel detailItem={product} handlePickPhoto={handlePickPhoto} />

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 0, m: 0, borderRadius: "5px",}}>

                        <Button component={Link} variant='contained'
                                sx = {{
                                    height: '40px',
                                    width: '120px',
                                    wordWrap: 'break-word',
                                    fontWeight: 500,
                                    color: '#ffffff',
                                    fontSize: '16px',
                                    mt: 2,
                                    borderRadius: 2,
                                    boxShadow: 0,
                                    backgroundColor: '#0096cd',
                                    '&.MuiButton-root:hover': {
                                        bgcolor: '#018BBD',
                                        boxShadow: 'none',
                                    }
                                }}
                                href={product?.product_data?.source_url}
                                target='_blank'
                        >
                            <a
                                style={{
                                    color: 'white',
                                    textDecoration: 'none',
                                }}
                                target='_blank'
                                >
                                Buy
                            </a>
                        </Button>

                        <Box sx={{ display: 'flex'}}>
                            <FavoriteBorderIcon sx = {{ fontSize: 24, color: '#0096cd', pt: 3}} />
                        </Box>
                    </Box>

                </Box>
            </Box>


            <Box sx={{width: '100%', textAlign: 'left', p: 1, pl: 0}}>

                <Typography sx={{fontWeight: '500', color: '#454545', fontSize: '14px'}}>
                    {product?.product_data?.brand} @ {product?.product_data?.source === 'walmart' ? 'Walmart' : product?.product_data?.source}
                </Typography>
                <Typography sx={{fontWeight: '500', color: '#454545', fontSize: '22px', lineHeight: '1.4'}}>
                    {decode(product?.product_data?.title)}
                </Typography>
                <Box sx={{pt: 1, height: '40px'}}>
                    {/* {product?.product_data?.rating && */}
                        <Box sx={{display: 'flex', ml: '-3px'}}>
                            <Rating sx={{paddingTop: '0px'}} precision={0.1} value={product?.product_data?.rating}/>
                            <Typography sx={{
                                fontWeight: '500',
                                color: '#737373',
                                fontSize: '16px',
                                pt: '2px'
                            }}>({product?.product_data?.rating === 0 ? '0 ratings' : product?.product_data?.rating })</Typography>
                        </Box>
                    {/* } */}
                </Box>
                <Box sx={{pt: 1, height: '50px',}}>
                    {/*<Typography sx={{fontWeight: '800', color: '#0096cd', fontSize: '22px'}}>${product?.product_data?.price}</Typography>*/}
                    {product?.product_data?.discounted_price && product?.product_data?.discounted_price !== product?.product_data?.price &&
                        <Typography sx={{
                            fontSize: '14px',
                            width: '250px',
                            fontWeight: '800',
                            color: '#000000',
                            // mb: 1,
                            textDecoration: 'line-through'
                        }}>${product?.product_data?.price}</Typography>
                    }
                    {(!product?.product_data?.discounted_price || product?.product_data?.discounted_price == product?.product_data?.price) &&
                        <Typography sx={{
                            fontWeight: '800',
                            color: '#0096cd',
                            fontSize: '22px'
                        }}>${product?.product_data?.price}</Typography>
                    }
                    {product?.product_data?.discounted_price && product?.product_data?.discounted_price !== product?.product_data?.price &&
                        <Typography sx={{
                            fontSize: '18px',
                            width: '250px',
                            fontWeight: '800',
                            color: '#0096cd',
                            mb: 1
                        }}>${product?.product_data?.discounted_price}</Typography>
                    }
                </Box>
                <br/>
                <Typography
                    variant='caption'
                    sx={{
                        fontWeight: '800',
                        color: '#666666',
                        fontSize: '16px'
                    }}>
                    Description:
                </Typography>
                <Box elevation={0}
                     sx={{flexGrow: 1, overflow: 'auto', height: '160px', marginTop: '0px', lineHeight: '1.4', mt: 1, mb: 1}}>
                    <Typography
                        variant='caption'
                        sx={{
                            fontWeight: '400',
                            color: '#adadad',
                            fontSize: '16px'
                        }}>
                        {product?.product_data?.description}
                    </Typography> </Box>


            </Box>
        </Stack>
        }
        </>



    )
}

export {DetailView}