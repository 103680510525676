import * as React from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import {Badge, Button, Grid, IconButton, Paper,} from '@mui/material/';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import StarIcon from '@mui/icons-material/Star';
import Tooltip from '@mui/material/Tooltip';
import {decode} from 'html-entities';


function ProductCard({product, updateDetail, openDetailedPanel}) {
    //console.log('product card props', product)
    let color = 'rgba(0, 150, 205, ' + (product.score / 1000).toFixed(1) + ')'
    // let color = 'rgb(0, ' + (160 - 5 * (product.score / 100).toFixed(1)) + ', ' + (215 - 5 * (product.score / 100).toFixed(1)) + ')'

    return (
        // was 360

        <Card raised={false}
              onClick={() => {updateDetail(product.id); openDetailedPanel()}}
              sx={{
                  minWidth: '235px',
                  maxWidth: '400px',
                  p: '10px 10px 0 10px',
                  m: '0 25px 25px 25px',
                  boxShadow: '0 0 20px 1px #dddddd',
                  borderRadius: '20px'
              }}>
            <Badge
                sx={{
                    left: '20px',
                    top: '20px',
                    display: 'block',
                    position: 'relative',
                    "& .MuiBadge-badge": {
                        height: '30px',
                        width: '30px',
                        borderRadius: '100%',
                        fontSize: '0.8rem',
                        backgroundColor: color,
                        color: 'white',
                    }
                }}
                // color="primary"
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                    margin: '2',
                    width: '250px',
                    height: '250px',
                    overflow: 'hidden',
                    objectFit: 'cover'
                }}
                badgeContent={(product.score / 100).toFixed(1)}>
            </Badge>
            <img style={{width: '100%', borderRadius: '20px', left: '-20px'}} src={product?.images[0]}></img>

            {/* alteration since there is no functionality for heart */}
            <Box
                sx={{display: 'flex', textAlign: 'left', p: 0, m: 1, borderRadius: 1, overflow: 'hidden'}}>
                {/* <Tooltip title={product?.product_data?.title} >*/}
                <Typography sx={{
                    color: 'black',
                    fontSize: 16, fontWeight: '500',
                    alt: decode(product?.product_data?.title),
                }}>

                    <div style={{width: 320, whiteSpace: 'nowrap'}}>
                        <Box
                            component="div"
                            sx={{
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                my: 0,
                                p: 0,
                            }}
                        >
                            <Tooltip title={decode(product?.product_data?.title)}>
                                {decode(product?.product_data?.title)}
                            </Tooltip>
                        </Box>
                    </div>
                    <div style={{width: 320, height: 40, whiteSpace: 'wrap'}}>
                        <Box
                            component="div"
                            sx={{
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                display: '-webkit-box',
                                my: 0,
                                p: 0,
                                mt: 1,
                                color: '#ADADAD',
                                fontSize: 14
                            }}
                            style={{
                                '-webkit-box-orient': 'vertical',
                                '-webkit-line-clamp': '2',
                            }}
                        >
                            <>{product?.product_data?.description}</>
                        </Box>
                    </div>
                    {/* {product?.product_data?.title ? product?.product_data?.title.slice(0, 35) + (product?.product_data?.title?.length > 35 ? '...' : '') : '&nbsp;'} */}
                </Typography>
                {/* </Tooltip> */}


            </Box>


            {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 0, m: 0, borderRadius: 1,}} >

                <Typography sx = {{ color: '#ADADAD', m: 0, fontSize: 12 }} gutterBottom variant="caption" component="div">
                                {product?.product_data?.description ? product?.product_data?.description.slice(0, 35) + (product?.product_data?.description?.length > 35 ? '...' : '') : '&nbsp;'}&nbsp;
                </Typography>
            </Box> */}

            <Box
                sx={{display: 'flex', justifyContent: 'space-between', p: 0, mt: 2, mb: 2, ml: '4px', mr: 1, borderRadius: 1,}}>
                <Box sx={{display: 'flex'}}>
                    <StarIcon sx={{fontSize: 18, color: `${product?.product_data?.rating ? 'orange' : '#ADADAD'}`}}/>
                    <Typography sx={{
                        color: 'black',
                        fontSize: 14,
                        fontWeight: '500',
                    }}>{product?.product_data?.rating}</Typography>
                </Box>

                <Typography sx={{
                    color: 'black',
                    fontSize: 14,
                    // fontFamily: 'SF Pro Text',
                    // fontFamily: 'SF Pro Display',
                    // sans-serif;

                    fontWeight: '800',
                    // lineHeight: 12,
                    // letterSpacing: 0.28,
                    wordWrap: 'break-word'
                }}
                >
                    ${product.product_data.discounted_price ? product.product_data.discounted_price : product.product_data.price}
                </Typography>

                <Typography sx={{
                    lineHeight: '1.2'
                }}>{product?.product_data?.source === 'walmart' ? 'Walmart' : product?.product_data?.source}</Typography>

            </Box>
        </Card>

    )
}

export {ProductCard}