import { signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { onAuthStateChanged, signOut } from "firebase/auth";
//import React, { useState } from "react";
import { auth, googleProvider } from "../firebase";
//import {ReactComponent as GoogleSigninLogo} from '../../assets/google.svg';
//import { useSelector, useDispatch } from 'react-redux';
//import { selectUser, setUser, logout} from '../redux/identitySlice';
//import { clearData} from '../redux/dataSlice';


//const dispatch = useDispatch()

const googleSignup = async () => {
    try {
      const z = await signInWithPopup(auth, googleProvider) //.then(result => console.log(result));
      console.log('signup', z.user)
      //dispatch(setUser(z.user))
    } catch (error) {
      console.log(error);
    }
  };

const gSignup = async () => {
    await googleSignup()
    //dispatch(clearData())
}

const gSignout = () => {
  signOut(auth)
    .then(() => {
      console.log("AuthDetails: sign out successful");
      //dispatch(clearData())
    })
    .catch((error) => console.log(error));
};

export const AuthFunctions = {
    googleSignInWithPopup: gSignup,
    googleSignOut: gSignout,

}