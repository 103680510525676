import React, { useState, useEffect, useRef, useCallback } from 'react';

import { SignIn } from './auth/SignIn'

import wordLogo from '../assets/buyerry.svg';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material/';

function SignInDialog({message, openDialog, handleCloseDialog}) {
    const [isSignup, setIsSignup] = useState(false);


    return (

        <Dialog //sx = {{ width: '250px'}}
            open={openDialog}
            onClose={handleCloseDialog}
            PaperProps={{
                sx: { width: "591px" },
                component: 'form',
                onSubmit: (event) => {
                    event.preventDefault();
                    const formData = new FormData(event.currentTarget);
                    const formJson = Object.fromEntries(formData.entries());
                    const email = formJson.email;
                    console.log(email);
                    handleCloseDialog();
                },
            }}>
            <DialogTitle>
                <Box sx = {{ position: 'relative', height: '100px' }}>
                <Box sx = {{ width: '100%', textAlign: 'center', position: 'absolute', top: '30%',  }}>
                    <img src={wordLogo} className="App-logo" alt="wordLogo" style={{height: '56px'}}  />
                </Box>
                </Box>
            </DialogTitle>
            <DialogContent sx = {{ textAlign: 'center'}}>
            <DialogContentText>
                {message}
                <SignIn />
            </DialogContentText>
            {/* <TextField autoFocus required margin="dense" id="name" name="email" label="Email Address" type="email" fullWidth variant="standard" /> */}
            </DialogContent>
            <DialogActions>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            {/* { isSignup ?
                <Button onClick={() => setIsSignup(false)}>Sign In</Button>
                :
                <Button onClick={() => setIsSignup(true)}>Sign Up</Button>
            } */}
            </DialogActions>
        </Dialog>
    )
}

export {SignInDialog}