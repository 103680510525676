import { createSlice } from '@reduxjs/toolkit'; 

const getProductsFromSearchId = (searchId, hist) => {
  console.log('looking up products from search id', searchId)
  
  console.log('from state.history', hist)
  const p = hist?.searchesProducts.searches.find(f => f.search_id === searchId).products
  //const pr = {products: p}
  console.log('products from products', p)

  const rezults = p?.map(e => {
    return { 
        images: e.medias,
        product_data: e.product_data,
        reviews: e.reviews,
        score: e.score
    }
})
console.log('rezults', rezults)
if (!rezults) return
//dispatch(setSearching(false))
//if (rezults) dispatch(setProducts(rezults))


  const rankedProducts = rezults.map((e, i) => { 
    e.id = e.product_data?.source + '-' + e.product_data?.id;
    if (e.product_data.source === 'circle') e.product_data.source = 'Target'
    return e}).sort( (a, b) =>  b.score - a.score || b.product_data.rating - a.product_data.rating )
  // console.log('product update:', rankedProducts, rankedProducts.map( (e, i) => { 
  //   e.id = e.product_data?.source + '-' + e.product_data?.id; return e}))
  // console.log('rankedp', rankedProducts)
  return rankedProducts
}

const getDiscourseFromSearchId = (searchId, history) => {
  //console.log('looking up session id from search id', searchId)
  const session_id = history.searchesProducts.searches.find(f => f.search_id === searchId).session_id
  //console.log('found session id', session_id)


  //console.log('sesssionx', history)
  const messages = history.sessionMessages.find(e => e.session_id === session_id).messages.sort((a, b) => parseInt(a.seq) - parseInt(b.seq))
  //console.log('sorted messages', messages)

//   {
//     "actor": "user",
//     "message": "i would like to buy a new lamp",
//     "timestamp": "2024-04-03T18:50:14.556Z"
// },
  const newdisco = messages.map((e, i) => { 
    return {
      actor: i%2===0 ? 'user' : 'ai',
      message: e.message_body,
      timestamp: e.created_at
    }})
  //console.log('new disco:', newdisco)
  return newdisco


  //const p = history.searchesProducts.searches.find(f => f.search_id === searchId).products
  //const pr = {products: p}
  //console.log('products from products', pr)
}

export const dataSlice = createSlice({
  name: 'data',
  initialState: {
    isInside: false,
    discourse: [],
    searching: false,
    products: null,
    history: null,
    searches: null,
    chatSessionId: null
  },
  reducers: {
    setChatSessionId: (state, action) => {
      //console.log('dataSlice: setChatSessionId reducer:', action.payload)
      return {
        ...state,
        chatSessionId: action.payload
    }

    },
    setInside: (state, action) => {
      //console.log('dataSlice: setInside reducer:', action.payload)
        return {
            ...state,
            isInside: action.payload ? true : false
        }
    },
    setVariablesFromSearchId: (state, action) => {

    },
    reloadDataPerSearchId: (state, action) => {
      // console.log('reloadDataPerSearchId', action.payload)
      const searchId = action.payload

      const historyCopy = JSON.parse(JSON.stringify(state.history))
      const productsCopy = JSON.parse(JSON.stringify(state.products))

      //console.log('state.history', historyCopy)
      //console.log('state.history', productsCopy)
      const iProducts = getProductsFromSearchId(searchId, historyCopy)
      //console.log('products', searchId, iProducts)


      // see workie!
      const dis = getDiscourseFromSearchId(searchId, historyCopy)
      // console.log('dis', dis)

      // console.log('orig products', state.products)
      // console.log('new products', iProducts)

      // console.log('orig discourse', state.discourse)
      // console.log('new discourse', dis)


      return {
        ...state,
        products: iProducts,
        discourse: dis
      }

    },
    setHistory: (state, action) => {
      //console.log('dataSlice: setHistory reducerx:', action.payload)

      const iSearches = action.payload?.searchesProducts?.searches.filter(s => s.source === 'circle').map(s => {
        return {
          session_id: s.session_id, search_id: s.search_id, search_summary: s.search_summary, created_at: s.created_at
        }
      }).sort( (a, b) => a.created_at - b.created_at)

    //   [
    //     {
    //         "images": [
    //             "https://target.scene7.com/is/image/Target/GUEST_23127e12-3d6e-4dde-b429-4713e5068645",
    //             "https://target.scene7.com/is/image/Target/GUEST_a718fea8-0027-4f79-b603-b153c15bf143",
    //             "https://target.scene7.com/is/image/Target/GUEST_79719429-fcec-41cf-98e8-3c758159912c",
    //             "https://target.scene7.com/is/image/Target/GUEST_055233cc-d342-4549-94c0-5216fea0865c",
    //             "https://target.scene7.com/is/image/Target/GUEST_418772ef-01a5-46f7-9de1-20cf63ad87ef",
    //             "https://target.scene7.com/is/image/Target/GUEST_d2446979-7b9a-4d8d-8c4f-862193aaf583",
    //             "https://target.scene7.com/is/image/Target/GUEST_42e4d6be-be17-4681-b6db-b75443b8c5ca",
    //             "https://target.scene7.com/is/image/Target/GUEST_b645cf24-d530-49ef-b8f7-877a7c1b7d0d"
    //         ],
    //         "product_data": {
    //             "brand": "Activision",
    //             "category": null,
    //             "color_hex": null,
    //             "color_name": null,
    //             "description": "Call of Duty®: Vanguard - Xbox Series X Standard Edition includes:; Call of Duty®: Vanguard. Includes  Xbox One and Xbox Series X version.",
    //             "discounted_price": null,
    //             "id": 11439,
    //             "price": 69.99,
    //             "product_code": "84198988",
    //             "product_id": 11439,
    //             "product_currency": "usd",
    //             "rating": 4.1,
    //             "short_description": null,
    //             "size": null,
    //             "source": "Target",
    //             "source_url": "https://www.target.com/p/call-of-duty-vanguard-xbox-series-x-xbox-one/-/A-84198988",
    //             "title": "Call of Duty: Vanguard - Xbox Series X/Xbox One"
    //         },
    //         "reviews": [],
    //         "score": 800,
    //         "id": "Target-11439"
    //     }
    // ]
      //console.log('search history (not persisted yet)', iSearches)

      // const searchId = 427
      // const iProducts = getProductsFromSearchId(searchId, action.payload)
      // console.log('products for 427', iProducts)
      // const dis = getDiscourseFromSearchId(searchId, action.payload)










        return {
            ...state,
            history: action.payload,
            searches: iSearches
        }
    },
    setSearching: (state, action) => {
      //console.log('dataSlice: setSearching reducer:', action.payload)
        return {
            ...state,
            searching: action.payload
        }
    },
    clearData: (state, action) => {
      return {
        ...state,

        //isInside: false,
        discourse: [], 
        searching: false,
        products: null,
        //history: null,
        chatSessionId: null
    }

    },
    loadDiscourse: (state, action) => {
      //console.log('adding discourse', action.payload)
      return {
        ...state,
        discourse: action.payload
      }
    },
    addToDiscourse: (state, action) => {
      //console.log('addToDiscourse: action.payload', action.payload)


      //console.log('APPEND QUERY', action.payload)
      const cc = [...state.discourse]
      const newDiscourse = action.payload
      newDiscourse.timestamp = new Date()
      cc.push(action.payload)
      //console.log('new discourse', cc)
      return {
          ...state,
          discourse: cc
      }
    },
    setProducts: (state, action) => {
      //console.log('set products: action.payload', action.payload)
      try {
        const rankedProducts = action.payload.map((e, i) => { 
          e.id = e.product_data?.source + '-' + e.product_data?.id;
          if (e.product_data.source === 'circle') e.product_data.source = 'Target'
          return e}).sort( (a, b) =>  b.score - a.score || b.product_data.rating - a.product_data.rating )
        //console.log('product update:', rankedProducts, rankedProducts.map( (e, i) => { 
        //  e.id = e.product_data?.source + '-' + e.product_data?.id; return e}))

        //console.log('set products', rankedProducts)
        return {
            ...state,
            products: rankedProducts
        }
      } catch (ex) {
        //console.log('error updating products', action.payload)
        return { ...state, products: []}

      }
    },




    // addColumn: (state, action) => {
    //   let newCols = [...state.columns];
    //   newCols = newCols.concat(action.payload);
    //   return {
    //     ...state,
    //     columns: newCols
    //   };
    // },
    // removeColumn: (state, action) => {
    //   let newCols = [...state.columns];
    //   newCols = newCols.filter(colDef => colDef.field !== action.payload);
    //   return {
    //     ...state,
    //     columns: newCols
    //   };
    // },
    // updateColumns: (state, action) => {
    //   let newCols = [...state.columns];
    //   newCols = newCols.map(col => {
    //     if (col.field == action.payload.field) {
    //       return action.payload;
    //     }
    //     return col;
    //   });
    //   return {
    //     ...state,
    //     columns: newCols
    //   };
    // }
  }
});

export const { addToDiscourse } = dataSlice.actions;

export const { setInside } = dataSlice.actions;
export const { setSearching } = dataSlice.actions;
export const { setHistory } = dataSlice.actions;
export const { setProducts } = dataSlice.actions;
export const { clearData } = dataSlice.actions;
export const { loadDiscourse } = dataSlice.actions;
export const { setChatSessionId } = dataSlice.actions;
export const { reloadDataPerSearchId } = dataSlice.actions;


export const selectIsInside = (state) => state.data.isInside;
export const selectDiscourse = (state) => state.data.discourse;
export const selectSearching = (state) => state.data.searching;
export const selectProducts = (state) => state.data.products;
export const selectHistory = (state) => state.data.history;
export const selectSearches = (state) => state.data.searches;
export const selectChatSessionId = (state) => state.data.chatSessionId;

//export const selectAuthToken = state => state.rows.bidAccuracy //used by main page
//export const authCredentials = (state) => state.identity.authCredentials;
//export const { addColumn, removeColumn, updateColumns } = columnSlice.actions;
//export const selectColumns = state => state.columns.columns;
export default dataSlice.reducer;