import React from 'react';
import {Stack, Box, Paper} from '@mui/material/';
import { selectSearches, reloadDataPerSearchId, selectChatSessionId, setChatSessionId} from '../redux/dataSlice';
import {
  selectAnonSearches, selectUser
} from "../redux/identitySlice";
import { useSelector, useDispatch } from 'react-redux';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import Divider from '@mui/material/Divider';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import Tooltip from '@mui/material/Tooltip';

import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


function HistorySelector({ history, leftPanelVisible }) {

  const searches = useSelector(selectSearches)
  //const sessionId = useSelector(selectChatSessionId)
  const anonSearches = useSelector(selectAnonSearches)
  const user = useSelector(selectUser)
  const chatSessionId = useSelector(selectChatSessionId)

  const dispatch = useDispatch()

  const selectSearchHistory = (searchId, sessionId) => {
    dispatch(reloadDataPerSearchId(searchId))
    dispatch(setChatSessionId(sessionId))
  }

    return (
        <div>
          {/* <p>boo {anonSearches}</p>
          <p>len {anonSearches?.length}</p>
          <p>sessionId {chatSessionId}</p>
          <p>user {!user ? 'anon' : JSON.stringify(user)}</p> */}
      <Accordion defaultExpanded sx={{
        background: 'none',
        boxShadow: 'none',
        cursor: 'pointer',
        textAlign: 'left',
        mt: 4,
      }}>
        {leftPanelVisible && (
            <>
          <Box aria-controls="panel1-content" id="panel1-header"
            sx={{
              background: 'none',
              boxShadow: 0,
              cursor: 'pointer',
              textAlign: 'left',
              mt: 5,
              mb: 3,
              display: 'flex',
              fontSize: '16px'
            }}>
              <Box sx={{
                pl: 3
            }}> Recent Searches</Box>
          </Box>
            <Divider sx={{
                mt: 2,
          }} />
          </>
        )}
        {!leftPanelVisible && (
          <Box aria-controls="panel1-content" id="panel1-header"
            sx={{
              background: 'none',
              boxShadow: 0,
              cursor: 'pointer',
              textAlign: 'center',
              mt: 5,
              mb: 2
            }}>
            <ManageSearchIcon sx={{
              pr: 1,
              pl: 1
            }}/><Divider sx={{
                mt: 2,
          }} />
          </Box>
        )}
        <AccordionDetails className='productItems'>
            <Stack spacing={0} sx={{
                    background: 'none',
                    boxShadow: 0,
                    cursor: 'pointer',
                    textAlign: 'left',
                  }}>

              {leftPanelVisible && (
                  searches?.filter(e => {
                    if (!user) return anonSearches?.includes(e.session_id)
                  
                  return e
                  }
                
                ).filter(item => item.search_summary).map((item, idx) => (
                  <Item sx={{
                    background: 'none',
                    boxShadow: 0,
                    cursor: 'pointer',
                    textAlign: 'left',
                    display: 'block',
                    ml: 1,
                    mt: 2,
                    mr: 1,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    fontSize: '16px',
                    '&.MuiPaper-root:hover': {
                      bgcolor: '#0096cd',
                      color: 'white',
                      boxShadow: 'none',
                    }
                  }}
                        key={idx}
                        onClick={() => selectSearchHistory(item.search_id, item.session_id)}>{item?.search_summary?.charAt(0).toUpperCase() + item?.search_summary?.slice(1)}
                  </Item>
                ))
              )}

              {!leftPanelVisible && (
                  searches?.filter(item => item.search_summary).map((item, idx) => (
                  <>
                  <Tooltip title={item?.search_summary?.charAt(0).toUpperCase() + item?.search_summary?.slice(1)}
                  sx={{
                      fontSize: '20px'
                  }}>
                  <Item sx={{
                    background: 'none',
                    boxShadow: 0,
                    cursor: 'pointer',
                    alignContent: 'center',
                    textAlign: 'center',
                    borderRadius: 100,
                    border: 'unset',
                    backgroundColor: '#f0f0f0',
                    width: '20px',
                    margin: '0 auto',
                    mt: 2,
                    fontSize: '16px',
                    '&.MuiPaper-root:hover': {
                      bgcolor: '#0096cd',
                      color: 'white',
                      boxShadow: 'none',
                      border: 'unset'
                    }
                  }}
                        key={idx}
                        onClick={() => selectSearchHistory(item.search_id, item.session_id)}>{idx + 1}
                  </Item>
                  </Tooltip>
                  </>
                ))
              )}
            </Stack>
        </AccordionDetails>
      </Accordion>

    </div>
    )
}

export {HistorySelector}